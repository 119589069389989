







































import VueWithStore, { Component } from '@/mixins'

import CustomizeColumnsDropdown from '@/components/minor/CustomizeColumnsDropdown.vue'

import { MutableFrom } from '@/store/utils';
import { MainStore } from '@/store'

@Component({
  components: { CustomizeColumnsDropdown }
})
export default class GuideHeader extends VueWithStore {

  @MutableFrom(MainStore) headerClosed!: boolean

  get mobileModal()       { return this.MainStore.mobileModal }
}
