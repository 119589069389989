import EyeCrossout               from '@/assets/eye-crossout.svg?inline'
import SortArrows                from '@/assets/sort-arrows.svg?inline'
import SortArrowUp               from '@/assets/sort-arrows-up.svg?inline'
import SortArrowDown             from '@/assets/sort-arrows-down.svg?inline'
import CheckboxUnchecked         from '@/assets/checkbox-unchecked.svg?inline'
import CheckboxChecked           from '@/assets/checkbox-checked.svg?inline'
import RadioButtonUnchecked      from '@/assets/radio-button-unchecked.svg?inline'
import RadioButtonChecked        from '@/assets/radio-button-checked.svg?inline'
import RadioButtonUncheckedLarge from '@/assets/radio-button-unchecked-large.svg?inline'
import RadioButtonCheckedLarge   from '@/assets/radio-button-checked-large.svg?inline'
import InfoCircle                from '@/assets/information-circle.svg?inline'
// import HamburgerOpen             from '@/assets/mobile-hamburger-open.svg?inline'
// import HamburgerClose            from '@/assets/mobile-hamburger-close.svg?inline'
import DownloadHover             from '@/assets/download-hover.svg?inline'
import DownloadRest              from '@/assets/download-rest.svg?inline'
import UrlHover                  from '@/assets/url-hover.svg?inline'
import UrlRest                   from '@/assets/url-rest.svg?inline'
import ExternalLink              from '@/assets/external-link.svg?inline'
import SearchMagnify             from '@/assets/search-magnify.svg?inline'

import type { Component, VueConstructor } from 'vue';

function generateSVGIcon(SVGIconAsVueConstructor: VueConstructor<Vue>): Component {
  return {
    functional: true,
    render(createElement) {
      return createElement(SVGIconAsVueConstructor)
    }
  }
}

function mapVuetifyIcons(iconObj: {
  [key: string]: VueConstructor<Vue>
}) {
  const mapped = {}

  Object.entries(iconObj).forEach(([name, svg]) => {
    mapped[name] = { component: generateSVGIcon(svg) }
  })

  return mapped
}

export default mapVuetifyIcons({
  EyeCrossout,
  SortArrows,
  SortArrowUp,
  SortArrowDown,
  CheckboxUnchecked,
  CheckboxChecked,
  RadioButtonUnchecked,
  RadioButtonChecked,
  RadioButtonUncheckedLarge,
  RadioButtonCheckedLarge,
  InfoCircle,
  // HamburgerOpen,
  // HamburgerClose,
  DownloadHover,
  DownloadRest,
  UrlHover,
  UrlRest,
  ExternalLink,
  SearchMagnify,
})
