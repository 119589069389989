
// <template functional>
//   <a v-if="props.href"
//     :href="props.href"
//     :class="[data.staticClass, data.class]"
//     :style="[data.staticStyle, data.style]"
//     v-bind="data.attrs"
//     v-on="listeners">
//     <span>{{data}}</span>
//     <slot></slot>
//   </a>
//   <span v-else>
//     <slot></slot>
//   </span>
// </template>

  import Vue, { RenderContext } from 'vue';

  export default Vue.component('TIFMaybeLink', {
      functional: true,
      props: {
        href: {
          type: String,
          required: false,
          default: null
        },
        fallbackTag: {
          type: String,
          required: false,
          default: null
        },
        linkClasses: {
          type: [String, Object]
        },
        nolinkClasses: {
          type: [String, Object]
        }
      },
      render(h, context: RenderContext) {
        const {data, props, children} = context;

        if(props.href) {
          const newData = {...data}

          // Add back href as an HTML attribute to the <a> tag
          newData.attrs = {...newData.attrs, href: props.href}

          if(props.linkClasses) {
            newData.class = [newData.class, props.linkClasses]
          }

          return h('a', newData, children)
        }
        else {
          const newData = {...data}

          if(props.nolinkClasses) {
            newData.class = [newData.class, props.nolinkClasses]
          }

          return h(props.fallbackTag || 'span', newData, children)
        }
      }
  });

