import type { KeyValue } from '@/types'
import { Dictionary } from 'lodash'

export enum FILTER_TYPES {
  CHECKBOX = 'CHECKBOX',
  RANGE = 'RANGE',                //<------ currently not in use.
  MIN_MAX_RANGE = 'MIN_MAX_RANGE'
}
interface BaseFilterDefiniton {
  name: string
  key: KeyValue
  customMatchingFunction?: ((...args: any) => boolean) // to be overriden in base interfaces
  type: FILTER_TYPES
}

export type CheckboxChoiceObj = {
  value: string
  display_name?: string
  pill_name?: string
  more_info?: boolean // TODO: make this a real obj
}
export type CheckboxChoiceObjWithIndex = CheckboxChoiceObj & {index: number}
export type CheckboxChoice = string | CheckboxChoiceObj | CheckboxChoiceObjWithIndex

export type TDomainMatcher = (value: any, domain: string[]) => boolean
export type TMinMaxRangeMatcher = (value: any, min: number, max: number) => boolean

export type MaybeFunction<T> = T | (() => T)

export interface CheckboxFilterDefinition extends BaseFilterDefiniton {
  name_single: string
  type: FILTER_TYPES.CHECKBOX
  chosen: number[]
  items: MaybeFunction< CheckboxChoice[] >
  item_count_map?: MaybeFunction< Dictionary<number> >
  customMatchingFunction?: TDomainMatcher
}

export interface RangeFilterDefinition extends BaseFilterDefiniton {
  type: FILTER_TYPES.RANGE
  ranges: {
    name?: string
    min: number
    max: number
    chosen: number
    hint?: string
  }[]
}

export interface MinMaxRangeFilterDefinition extends BaseFilterDefiniton {
  type: FILTER_TYPES.MIN_MAX_RANGE
  range: {
    name?: string
    min: number
    max: number
    enabled: boolean
    chosen: [number, number]
    hint?: string
  }
  customMatchingFunction?: TMinMaxRangeMatcher
}

export type FilterDefinition =
  CheckboxFilterDefinition |
  RangeFilterDefinition |
  MinMaxRangeFilterDefinition

export interface ColumnDefinition {
  name: string
  key: KeyValue
  description?: string
  default?: boolean
  nosort?: boolean
  customSortFunction?: (a: any, b: any) => number
  width?: string | number
}

export interface TableDefinition
{
  filterOptions: FilterDefinition[]
  columnOptions: ColumnDefinition[]
}

export interface ChosenFilterObj {
  index: number
  subindex?: number
  item: string
}

/**
 * URL LINK TYPES
 */

export type FiltersChosenByKey = {
  [K in KeyValue]?: string[]
}
export interface ColumnFilterQueryParam {
  columns: KeyValue[]
  filters?: FiltersChosenByKey
}
