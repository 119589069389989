






































































import VueWithStore, {Component, Prop} from '@/mixins'

import { isMinMaxRangeFilterDefinition } from '@/schema'
import type { MinMaxRangeFilterDefinition } from '@/types'

@Component
export default class MinMaxRangeFilter extends VueWithStore {

  @Prop({required: true, validator: isMinMaxRangeFilterDefinition}) source!: MinMaxRangeFilterDefinition
  @Prop({required: true}) index!:  number

  local_min_key = 0
  local_max_key = 0

  processMinMaxRangeFilterChange(index: number){
    return (newChoices: [number, number]) => {
      this.TableStore.updateFilter({
        index, newChoices
      })
    }
  }

  lazilyUpdateModelFromNumberFields(index: number, inputValue: string) {

    const inputValueAsNum = Number(inputValue)
    if(!Number.isNaN(inputValueAsNum)) {

      const boundedValue = Math.max(this.source.range.min, Math.min(this.source.range.max, inputValueAsNum))

      this.$set(this.source.range.chosen, index, boundedValue)

      if(boundedValue === inputValueAsNum)
        return
    }

    // At this point:
    // inputValueAsNum is NaN or boundedValue !== inputValueAsNum:

    // This is hacky and I hate it but it works and is necessary for the damn Vuetify components that are not well designed.
    index == 0 ? ++this.local_min_key : ++this.local_max_key
  }
}

