import type {
  PublicationObjectInput,
  PublicationObjectOutput,
  IdNamePair,
  IdNameMap,
  APIResponse_Authors,
  APIResponse_Journals,
  // APIResponse_Categories,
  APIResponse_Publications,
} from '@/types'

// Hard coding data for now:

import AuthorsDataInput from '@/data/authors.json'
// import CategoriesDataInput from '@/data/categories.json'
import JournalsDataInput from '@/data/journals.json'
import PublicationsData from '@/data/publications.json'

export const AuthorsMap     = transformIdNamePairsToMap((AuthorsDataInput as APIResponse_Authors).authors)
export const JournalsMap    = transformIdNamePairsToMap((JournalsDataInput as APIResponse_Journals).journals)
// export const CategoriesMap  = transformIdNamePairsToMap((CategoriesDataInput as APIResponse_Categories).categories)

function transformIdNamePairsToMap(input: IdNamePair[]): IdNameMap {
  return Object.assign({}, ...input.map(
      pair => ({
        [pair.id]: pair.name
      })
    )
  )
}

/////////////////////////////////////////////////////////////////////////////

import axios from 'axios'
import dayjs from 'dayjs'

import type { DataRow } from '@/types';

function restructureAPIData (input_data_from_API: PublicationObjectInput[]): PublicationObjectOutput[] {

  function unescapeURL (url?: string) {
    return url ? url.replaceAll('\\/', '/')
                    .replace('//gelest/','//www.gelest.com/') : null
  }

  return input_data_from_API.map( pub => {
    const new_obj = {
      TIF___name:         pub.article_title,
      TIF___external_URL: unescapeURL(pub.link),
      journal_name:       JournalsMap[pub.journal_id],
      author_names:       pub.author_ids.map(id => AuthorsMap[id]),
      // category_names:     pub.category_ids.map(id => CategoriesMap[id]),
      date_published:     pub.year,
      ...pub
    }

    return new_obj
  })
}

const IF_PRODUCTION = process.env.NODE_ENV === 'production'
const IS_WP_BUILD = !!process.env.VUE_APP_WP_BUILD

const API_DOMAIN = IF_PRODUCTION ?
  (
    IS_WP_BUILD
      ? '../' // because the WP build will always be 1 subdir down from the files
      : 'https://cors-anywhere.herokuapp.com/http://www.gelest.com/'
        // temporary CORS avoidance for Netlify builds
  )
  : 'http://gelest-guides.local/' //dev


// async function fetchManifest(): Promise<GuideHashManifest | false> {
//   try {
//     // This will ensure that the browser-cached manifest will be cache busted at least every day
//     // "| 0" is a shorthand for enforcing integer division (binary decimal truncation)
//     const UNIQUE_BY_DAY = Date.now() / (1000 * 60 * 60 * 24) | 0

//     const response = await axios.get(API_DOMAIN + 'publications-manifest.json?v=' + UNIQUE_BY_DAY)

//     if(response.status == 200)
//       return response.data
//     else throw response
//   }
//   catch(e) {
//     console.error(e)
//     return false
//   }
// }


async function fetchJSONFile(): Promise<APIResponse_Publications | false> {
  try {
    // const HASH_MANIFEST = await fetchManifest();
    // if(!HASH_MANIFEST)
    //   return false

    // const GUIDE_KEY_NAME = GUIDES[guideNum] as GuideKeys

    // const JSON_LATEST_HASH = HASH_MANIFEST[GUIDE_KEY_NAME]

    // const JSON_FILENAME = GuideDataRoutes[guideNum]

    // const response = await axios.get(API_DOMAIN + JSON_FILENAME + '?v=' + JSON_LATEST_HASH)

    // if(response.status == 200)
    //   return response.data
    // else throw response
    return Promise.resolve(PublicationsData)
  }
  catch(e) {
    console.error(e)
    return false
  }
}

export async function fetchTableData(): Promise<DataRow[] | false> {

  const newGuideData = await fetchJSONFile()
  if(!newGuideData)
    return false

  return restructureAPIData(newGuideData.publications)
}
