


































import VueWithStore, {Component, Watch} from '@/mixins'
import { stringifyQuery } from '@/router'

@Component
export default class CopyUrlLink extends VueWithStore {

  copyURLText = "Copy a unique link to visit this guide again with the current column and filter choices"
  urlWasCopied = false
  linkIconIsHovered = false

  get getURLWithColumnFilterQueryParam() {
    const code = this.TableStore.encodedColumnFilterQueryParam

    // keep all other query parameters, if they exist
    // replacing an existing "cf" param with the "real" one from the data store
    const queryParams = { ...this.$route.query, cf: code }

    const queryAsString = stringifyQuery(queryParams)

    const deployDir = String(process.env.BASE_URL).replace(/\/$/, '')

    return  `${ window.location.origin  // e.g. http://guides.gelest.com
            }${ deployDir               // either '' or '/guides' depending on deployment target
            }${ this.$route.path        // e.g. /metal-organics
            }${ queryAsString }`        // e.g. ?cf=ABCDEFG
  }

  onClipboardCopy () {
    this.urlWasCopied = true
  }

  onClipboardError (e: Error) {
    alert('Failed to copy link')
  }

  @Watch('linkIconIsHovered')
  onMouseAway(newState: boolean) {
    if(newState === false && this.urlWasCopied) {
      this.urlWasCopied = false
    }
  }
}

