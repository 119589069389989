














































































import VueWithStore, { Component, Vue } from "@/mixins";
import { filter } from "vue/types/umd";

@Component<SearchBar>({
  components: {}
})
export default class SearchBar extends VueWithStore {
  searchInput: string = "";
  searchInputMsg: string = "Click here or press enter to filter the table and close this menu.";
  menuOpen: boolean = false;
  prevSearchInput: string = "";

  get unfilteredTableData() {
    return this.TableStore.guideData;
  }

  get dropDownData() {
    return this.unfilteredTableData.map(article => ({
      title: article.article_title,
      link: article.TIF___external_URL,
      // categories: article.category_names,
      abstract: article.abstract,
      year: article.date_published,
      group: "Titles"
    }));
  }

  get dropDownAbstractData() {
    const abstracts = this.dropDownData
      .filter(article => {
        return article.abstract;
      })
      .map(article => {
        return { ...article, group: "Abstracts" };
      });
    return abstracts;
  }

  get reducedGroupedDropDownData() {
    const return_array: object[] = [];
    if (this.searchInput && this.searchInput.length >= 3) {
      const filtered_titles = this.dropDownData
        .filter(
          item =>
            item.title &&
            item.title
              .toLocaleLowerCase()
              .indexOf(this.searchInput?.toLocaleLowerCase() || "") > -1
        )
        .sort((a, b) => parseInt(b.year) - parseInt(a.year))
        .slice(0, 6);

      const filtered_abstracts = this.dropDownAbstractData
        .filter(
          item =>
            item.abstract &&
            item.abstract
              .toLocaleLowerCase()
              .indexOf(this.searchInput?.toLocaleLowerCase() || "") > -1
        )
        .sort((a, b) => parseInt(b.year) - parseInt(a.year))
        .slice(0, 6);

      if (filtered_titles.length) {
        return_array.push(
          { header: "Most Recent by Title" },
          ...filtered_titles
        );
      }
      if (filtered_abstracts.length) {
        if (filtered_titles.length) {
          return_array.push({ divider: true });
        }
        return_array.push(
          { header: "Most Recent by Abstract" },
          ...filtered_abstracts
        );
      }
      this.menuOpen = true
    }
    return return_array;
  }

  get dropDownNoDataText() {
    return this.searchInput && this.searchInput.length >= 3
      ? "We were unable to find any Publications with the term(s) provided. Please alter your search query."
      : "Please enter a few more characters. ( 3 character minimum )";
  }

  get menuProps() {
    return { closeOnContentClick: false, closeOnClick: false, value: this.menuOpen }
  }

  onChange() {
    if(this.searchInput != this.prevSearchInput) {
      this.menuOpen = true
      this.$store.commit("TableStore/updateSearchTerm", this.searchInput);
    }
    this.prevSearchInput = this.searchInput;
  }

  onEnter() {
    this.menuOpen = false
  }
}
