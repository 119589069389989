












































































import { MutableFrom } from '@/store/utils'

import { TableStore } from '@/store';

import VueWithStore, {Component, Prop, Watch} from '@/mixins'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

@Component
export default class CustomizeColumnsDropdown extends VueWithStore {

  @Prop({default: false}) mobile!: boolean

  get columnOptions() { return this.TableStore.columnOptions; }
  get loadingScreen() { return this.MainStore.loadingScreen }

  @MutableFrom(TableStore) chosenColumns!: typeof TableStore.chosenColumns;


  // using this as an array is easier than using it without the `multiple` prop on the element above
  expandedDropdowns = []

  get customizeColumnDropdownIsOpen() {
    return !!this.expandedDropdowns.length
  }

  $refs!: {
    'ccd-scrollable-content': Vue
  }

  @Watch('customizeColumnDropdownIsOpen')
  onMobileButtonExpansion(newlyOpened: boolean) {
    if(!this.mobile) return

    if(newlyOpened) {
      disableBodyScroll(this.$refs['ccd-scrollable-content'].$el);

      // Apparently Safari needs this
      Array.from(document.getElementsByTagName('html')).forEach( a => a.style.overflow = 'hidden')
    }
    else {
      enableBodyScroll(this.$refs['ccd-scrollable-content'].$el);

      // Apparently Safari needs this
      Array.from(document.getElementsByTagName('html')).forEach( a => a.style.overflow = '')
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

}

