






























































































































































import VueWithStore, {Component, Prop} from '@/mixins'

import { maybeCallFn, normalizeCheckboxValueToChoiceObject } from '@/store/modules/table'
import { isCheckboxFilterDefinition } from '@/schema'
import type { CheckboxChoice, CheckboxChoiceObj, CheckboxChoiceObjWithIndex, CheckboxFilterDefinition } from '@/types'

interface CheckboxRowObject extends CheckboxChoiceObjWithIndex {
  visible: boolean
}

@Component
export default class CheckboxGroupFilter extends VueWithStore {

  maybeCallFn = maybeCallFn // connecting this to the Vue component

  @Prop({required: true, validator: isCheckboxFilterDefinition}) source!: CheckboxFilterDefinition
  @Prop({required: true}) index!: number

  processCheckboxesFilterChange(index: number){
    return (newChoices: number[]) => {
      this.TableStore.updateFilter({
        index, newChoices
      })
    }
  }

  extrapolateAsObjects(itemsArray: CheckboxChoice[]): CheckboxChoiceObj[] {
    return itemsArray.map( normalizeCheckboxValueToChoiceObject )
  }

  parenthesesCount(input: CheckboxChoiceObj) {
    const lookup_dict = maybeCallFn(this.source.item_count_map)
    if(lookup_dict && lookup_dict[input.value]) {
      return `(${lookup_dict[input.value]})`
    }
    return ''
  }

  /* * * * * * * * * * * * * * * * * * *
   *
   *  "Show More" functionality
   *
   * * * * * * * * * * * * * * * * * * */

  truncationIsExpanded = false

  @Prop({required: false, default: 0}) itemLimit!: number

  // This value also considers how many of the checkboxes are checked right now
  // to ensure that we always show all checked options, even if an item limit is in place
  get computedItemLimit() {
    if(!this.itemLimit) return 0

    if(this.drawerSearchInput.length > 0)
      return this.itemLimit

    return Math.max(this.itemLimit, this.numSelectedItems)
  }

  get numSelectedItems(): number {
    return this.source.chosen.length
  }

  get numVisibleItems(): number {
    return this.filteredItems.reduce( (sum, item) => sum + (+item.visible), 0 )
  }

  get showMoreLessButton(): boolean {
    return this.computedItemLimit > 0 && this.numVisibleItems > this.computedItemLimit
  }

  get gridIsCollapsed() {
    return this.showMoreLessButton && !this.truncationIsExpanded
  }


  /* * * * * * * * * * * * * * * * * * *
   *
   *  Filter List with Text Query functionality
   *
   * * * * * * * * * * * * * * * * * * */

  filterSearchInput = ''

  get drawerSearchInput(): string {
    return this.filterSearchInput ? this.filterSearchInput.trim().toLocaleLowerCase() : ''
  }

  get itemsWithIndices() {
    return maybeCallFn(this.source.items).map(normalizeCheckboxValueToChoiceObject)
  }

  get filteredItems(): CheckboxRowObject[] {

    const itemsWithIndicesAndVis = this.itemsWithIndices.map( item => {

      let thisItemIsVisible = true
      if(this.drawerSearchInput) {
        // when the local search filter is active
        thisItemIsVisible = item.value.toLocaleLowerCase().indexOf(this.drawerSearchInput) > -1
      }

      return {
          ...item,
          visible: thisItemIsVisible
        }
    })

    return itemsWithIndicesAndVis
  }

}
