// call this here so that all components that import this file will run this as a side-effect
import 'reflect-metadata'

export * from 'vue-property-decorator'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { TableStore, MainStore } from '@/store'

@Component
export default class VueWithStore extends Vue {

  TableStore = TableStore
  MainStore = MainStore

  // Global app height vars

  // If you change these constants, you'll need to change the SASS too in global_vars.scss
  readonly TOP_BAR_HEIGHT = 83
  readonly SEARCH_BAR_HEADER_HEIGHT = 137
  readonly INDIV_GUIDE_HEADER_HEIGHT__MOBILE = 48
  readonly INDIV_GUIDE_HEADER_HEIGHT__DESKTOP = 69

}
