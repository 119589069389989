import Vue from 'vue'
import VuetifyFramework from 'vuetify/lib/framework'
import type Vuetify from 'vuetify/types'
import type { Theme } from 'vuetify/types/services/theme'

import minifyTheme from 'minify-css-string'
import SVGIcons from './icons'

Vue.use(VuetifyFramework)

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

const theme: DeepPartial<Theme> = {
  options: {
    variations: true,
    minifyTheme,
    customProperties: true
  },
  themes: {
    light: {
      primary: '#24439C',
      secondary: '#00AB79',

      bggray: '#F1F4F6',
      lightgray: '#F7F8F9',
      gray: '#D8DFE3',
      midgray: '#98A9B3',
      offblack: '#383C3A',

      //these are the defaults for these ones:
      // accent: '#82B1FF',
      // error: '#FF5252',
      // info: '#2196F3',
      // success: '#4CAF50',
      // warning: '#FFC107',
    }
  }
}


const vuetify: Vuetify = new VuetifyFramework({
  breakpoint: {
    mobileBreakpoint: 'xs'
  },
  theme,
  icons: {
    values: {
      downLeftAngleArrow: 'mdi-subdirectory-arrow-left',

      ...SVGIcons
    }
  }
})

export default vuetify
