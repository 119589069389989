import Vue from 'vue'

import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

import VueDayjs from 'vue-dayjs';

Vue.use(VueDayjs, {
  lang: 'en'
})
