import 'vue-class-component/hooks' // import hooks type to enable auto-complete

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import './plugins/vue-meta'
import './plugins/vue-clipboard'
import './plugins/vue-dayjs'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#vue-gelest-publications')
