import type { CheckboxFilterDefinition, FilterDefinition, MinMaxRangeFilterDefinition, RangeFilterDefinition } from '@/types'
import { FILTER_TYPES } from '@/types'

export function isRangeFilterDefinition(filterItem: FilterDefinition):
  filterItem is RangeFilterDefinition
{
  return filterItem?.type == FILTER_TYPES.RANGE
}

export function isMinMaxRangeFilterDefinition(filterItem: FilterDefinition):
  filterItem is MinMaxRangeFilterDefinition
{
  return filterItem?.type == FILTER_TYPES.MIN_MAX_RANGE
}

export function isCheckboxFilterDefinition(filterItem: FilterDefinition):
  filterItem is CheckboxFilterDefinition
{
  return filterItem?.type == FILTER_TYPES.CHECKBOX
}
