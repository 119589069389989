















































































































































import VueWithStore, { Component } from '@/mixins'

import { MainStore } from '@/store'
import { MutableFrom } from '@/store/utils'

@Component
export default class WizardStepper extends VueWithStore {

  @MutableFrom(MainStore) wizard_step!: typeof MainStore.wizard_step

}
