







































































import SearchMain               from './SearchMain.vue'
import SearchDrawer             from './SearchDrawer.vue'
import SearchBar                from './SearchBar.vue'
import WizardStepper            from './WizardStepper.vue'
import TopHeader                from './TopHeader.vue'
import GuideHeader              from './GuideHeader.vue'
import CustomizeColumnsDropdown from './minor/CustomizeColumnsDropdown.vue';
import BackToGelestButton       from './minor/BackToGelestButton.vue';

import      { WIZARD_STEPS } from '@/store/modules/main'
import type { Route        } from 'vue-router/types/router'

import VueWithStore, { Component, Prop, Watch } from '@/mixins'

import { MainStore } from '@/store'
import { MutableFrom } from '@/store/utils'

@Component<Publications>({
  components: {
    SearchMain,
    SearchDrawer,
    SearchBar,
    WizardStepper,
    TopHeader,
    GuideHeader,
    CustomizeColumnsDropdown,
    BackToGelestButton
  }
})
export default class Publications extends VueWithStore {

  get loading_screen() { return this.MainStore.loadingScreen; }

  get isSearchScreenStep() {
    return this.MainStore.wizard_step >= WIZARD_STEPS.SEARCH_SCREEN
  }

  @Watch('$route', {immediate: true, deep: true})
  async onRouteChange (newRoute: Route, oldRoute: Route | undefined) {

    if(!oldRoute || newRoute.path !== oldRoute.path) {
      // This is necessary to make sure the guide always switches when the route changes
      // e.g. when the back/forward buttons are used in the browser
      //
      // Note that we only want to load a new guide if the *path* changes; not if query params change
      await this.TableStore.loadTableData()

      if( newRoute.query.cf &&
          typeof newRoute.query.cf === 'string') {
        await this.TableStore.handleInputQueryParam(newRoute.query.cf)
      }
    }
  }


  // Mobile modal handling

  @MutableFrom(MainStore) mobileModal!: typeof MainStore.mobileModal

  @Watch('$vuetify.breakpoint.mobile', {immediate: true})
  onMobileChange(isMobile: boolean) {
    if(!isMobile)
      this.mobileModal = false
  }

  get appBarHeight() {
    if(this.$vuetify.breakpoint.mobile) {
      return this.TOP_BAR_HEIGHT + this.INDIV_GUIDE_HEADER_HEIGHT__MOBILE
    }
    else {
      if(this.MainStore.headerClosed) {
        return "0"
        // For some reason, 0 as an integer doesn't work... seems to be because of a bad conditional check in the core code
        // https://github.com/vuetifyjs/vuetify/issues/3990#issuecomment-506959690
      }
      else {
        return this.TOP_BAR_HEIGHT + this.SEARCH_BAR_HEADER_HEIGHT
      }
    }
  }

}
