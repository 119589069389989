import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import { TableStore, MainStore } from './modules'

const store = new Vuex.Store({
  modules: {
    TableStore, MainStore
  }
});

import { getModule } from 'vuex-module-decorators'

const TableStore_instance = getModule(TableStore, store);
const MainStore_instance  = getModule(MainStore, store);

export {TableStore_instance as TableStore,
        MainStore_instance  as MainStore}

export default store
