





























































































































import { FilterDefinition, FILTER_TYPES } from '@/types'

import { MainStore } from '@/store'
import { MutableFrom } from '@/store/utils'

import VueWithStore, { Component, Watch } from '@/mixins'

import RangeFilter from './minor/RangeFilter.vue';
import MinMaxRangeFilter from './minor/MinMaxRangeFilter.vue';
import CheckboxGroupFilter from './minor/CheckboxGroupFilter.vue';

import { maybeCallFn } from '@/store/modules/table';

@Component({
  components: { RangeFilter, MinMaxRangeFilter, CheckboxGroupFilter }
})
export default class SearchDrawer extends VueWithStore {

  FILTER_TYPES = FILTER_TYPES

  get filterOptions() { return this.TableStore.filterOptions }
  get loadingScreen() { return this.MainStore.loadingScreen }


  // Drawer State
  @MutableFrom(MainStore) drawerClosed!: boolean

  @Watch('$vuetify.breakpoint.smAndDown', {immediate: true})
  onMobileChange(isMobile: boolean) {
    this.drawerClosed = isMobile
  }

  parenthesesCount(source: FilterDefinition) {
    if(source.type == FILTER_TYPES.CHECKBOX) {
      const numChosen = source.chosen.length
      const numOptions = maybeCallFn(source.items).length
      if(numChosen) {
        return `(${numChosen}/${numOptions})`
      }
      else
        return `(${numOptions})`
    }
    return ''
    // source.type == FILTER_TYPES.MIN_MAX_RANGE
    // source.type == FILTER_TYPES.CHECKBOX
  }

}
