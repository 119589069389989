
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Mutable } from '../utils'

import { PublicationsDefinition } from '@/schema'

import { fetchTableData } from '../api';
import {
  isCheckboxFilterDefinition,
  isMinMaxRangeFilterDefinition
} from '@/schema';
import {
  defaultCheckboxMatchingFunction,
  defaultMinMaxrangeMatchingFunction
} from '@/schema/matchers'

// https://medium.com/javascript-in-plain-english/leveraging-type-only-imports-and-exports-with-typescript-3-8-5c1be8bd17fb
// https://devblogs.microsoft.com/typescript/announcing-typescript-3-8/#type-only-imports-exports
import type {
  // ChosenFilterObj,
  ColumnDefinition,
  DataRow,
  FilterDefinition,
  KeyValue,
  TableDefinition,
  CheckboxChoice,
  CheckboxChoiceObjWithIndex,
  ColumnFilterQueryParam,
  FiltersChosenByKey,
  IdNameMap
} from '@/types';

import { FILTER_TYPES } from '@/types'

import Base64 from 'base-64'
import deepmerge from 'deepmerge'


/**
 * HELPER FUNCTIONS
 */

export function normalizeCheckboxChoiceToValue(choice: CheckboxChoice): string {
  return (typeof choice === 'string') ? choice : choice.value
}

export function normalizeCheckboxValueToChoiceObject(item: CheckboxChoice, index: number): CheckboxChoiceObjWithIndex {
  if (typeof item === 'string') {
    return ({ value: item, index })
  }
  else if (!("index" in item)) {
    return ({ ...item, index })
  }
  else return item
}

function isNotNull<T>(arg: T): arg is Exclude<T, null> {
  return arg !== null
}

function isNotNullOrUndef<T>(arg: T): arg is Exclude<Exclude<T, null>, undefined> {
  return arg !== null && arg !== undefined
}

import { MaybeFunction } from '@/types/table-definition';
export function maybeCallFn<T>(var_or_function: MaybeFunction<T>) {
  if (var_or_function instanceof Function)
    return var_or_function()
  else return var_or_function
}


import _countBy from 'lodash/countBy'
import _mapKeys from 'lodash/mapKeys'

import { 
  AuthorsMap, 
  // CategoriesMap, 
  JournalsMap } from '@/store/api'

function getCountsFromData(this: TableStore, id_array_fieldname: KeyValue, mapFromIdToName: IdNameMap) {
  const allIds = this.guideData
    .map(row => row[id_array_fieldname])
    .filter(isNotNullOrUndef)
    .flat()

  const id_counts = _countBy(allIds)

  const name_counts = _mapKeys(id_counts, (_, id) => mapFromIdToName[id])

  return name_counts
}


/**
 * TABLE STORE MODULE
 */
@Module({ namespaced: true, name: 'TableStore' })
export default class TableStore extends VuexModule {

  filterOptions: FilterDefinition[] = []
  columnOptions: ColumnDefinition[] = []

  @Mutable() chosenColumns: KeyValue[] = []
  @Mutable() searchInput: string = "";

  guideData: DataRow[] = []

  
  get filteredGuideData() {
    if (this.searchInput && this.searchInput.length >= 3) {
      return this.guideData.filter(this.combinedFilterFunction)
        .filter(
          item =>
            item.TIF___name &&
            item.TIF___name
              .toLocaleLowerCase()
              .indexOf(this.searchInput?.toLocaleLowerCase() || "") > -1
            ||
            item.abstract &&
            item.abstract
              .toLocaleLowerCase()
              .indexOf(this.searchInput?.toLocaleLowerCase() || "") > -1
        )
    }
    return this.guideData
      .filter(this.combinedFilterFunction)
      .sort((a: any, b: any) => parseInt(b.year) - parseInt(a.year))
  }

  get authorCounts() {
    
    return getCountsFromData.call(this, 'author_ids', AuthorsMap)
  }
  // get categoryCounts() {
  //   return getCountsFromData.call(this, 'category_ids', CategoriesMap)
  // }
  get journalCounts() {
    return getCountsFromData.call(this, 'journal_id', JournalsMap)
  }

  get listOfAuthors() {  
    return Object.keys(this.authorCounts)
    .sort( (a: string, b: string) =>  
        a.substring(a.indexOf(' ') + 1) > b.substring(b.indexOf(' ') + 1) ? 1 
      : a.substring(a.indexOf(' ') + 1) < b.substring(b.indexOf(' ') + 1) ? -1 
      : 0
    )
  }
  // get listOfCategories() {
  //   return Object.keys(this.categoryCounts).sort()
  // }
  get listOfJournals() {
    return Object.keys(this.journalCounts).sort()
  }

  // get listOfChosenFilterPills() {
  //   const resp: ChosenFilterObj[] = []

  //   return this.filterOptions.reduce((a, c, index) => {
  //     if(c.type === FILTER_TYPES.CHECKBOX)
  //     {
  //       return a.concat(
  //         maybeCallFn(c.items)
  //           .map((item, subindex) => ({
  //               item: typeof item === 'string' ? item : item.pill_name || item.display_name || item.value,
  //               subindex,
  //               index
  //             })
  //           )
  //           .filter(({ subindex }) => c.chosen.includes(subindex))
  //       )
  //     }
  //     else if(c.type === FILTER_TYPES.MIN_MAX_RANGE)
  //     {
  //       if(c.range.enabled) {
  //         let item: string;
  //         if(c.range.chosen[0] !== c.range.chosen[1])
  //           item = `${c.name}, from ${c.range.chosen[0]} to ${c.range.chosen[1]}`
  //         else
  //           item = `${c.name}: ${c.range.chosen[0]}`

  //         return a.concat([{ index, item }])
  //       }
  //       else return a
  //     }
  //     else return a
  //   }, resp)
  // }

  get combinedFilterFunction() {

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const checkboxesWithActiveFilters = this.filterOptions
      .filter(isCheckboxFilterDefinition)
      .filter(filterItem => filterItem.chosen.length)

    const checkboxFilteringFns = checkboxesWithActiveFilters
      .map(filterItem => {

        const key = filterItem.key
        if (key) {
          const domain = filterItem.chosen
            .map(index => maybeCallFn(filterItem.items)[index])
            .map(normalizeCheckboxChoiceToValue)
            .map(strValue => strValue.toLocaleLowerCase())

          return function (dataRow: DataRow) {
            // if we're doing any filtering at all on this domain, filter out empty values in the dataset
            if (!dataRow.hasOwnProperty(key)) {
              return false
            }

            if (filterItem.customMatchingFunction) {
              return filterItem.customMatchingFunction(dataRow[key], domain)
            }
            else return defaultCheckboxMatchingFunction(dataRow[key], domain)
          }
        }
        else return null
      })
      .filter(isNotNull)

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const minMaxRangesWithActiveFilters = this.filterOptions
      .filter(isMinMaxRangeFilterDefinition)
      .filter(filterItem => filterItem.range.enabled === true)

    const minMaxFilteringFns = minMaxRangesWithActiveFilters
      .map(filterItem => {

        const key = filterItem.key
        if (key) {
          const [min, max] = filterItem.range.chosen

          return function (dataRow: DataRow) {
            // don't filter this row if it doesn't even have the datapoint in question
            if (!dataRow.hasOwnProperty(key)) return true

            if (filterItem.customMatchingFunction) {
              return filterItem.customMatchingFunction(dataRow[key], min, max)
            }
            else return defaultMinMaxrangeMatchingFunction(dataRow[key], min, max)
          }
        }
        else return null
      })
      .filter(isNotNull)

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const combinedFilterFunction = function (dataRow: DataRow) {
      return [
        ...checkboxFilteringFns,
        ...minMaxFilteringFns
      ].reduce((partialBooleanResult, func) => partialBooleanResult && func(dataRow), true) // start with 'true' value == no filtering
    }

    return combinedFilterFunction
  }

  get listOfChosenColumns() {
    return this.columnOptions
      .filter(col_obj => this.chosenColumns.includes(col_obj.key))
  }

  @Mutation updateSearchTerm(searchInput) {
    this.searchInput = searchInput
  }

  @Mutation removeColumnByKey(key: string) {
    const idxInChoices = this.chosenColumns.findIndex(x => {
      return x === key
    })

    if (~idxInChoices) {
      this.chosenColumns.splice(idxInChoices, 1)
    }
  }

  @Mutation clearFilters() {
    this.filterOptions.forEach(category => {
      if (category.type === FILTER_TYPES.CHECKBOX) {
        category.chosen = []
      }
      else if (category.type === FILTER_TYPES.MIN_MAX_RANGE) {
        category.range.chosen = [category.range.min, category.range.max]
        category.range.enabled = false
      }
    })
  }

  @Mutation updateFilter({ index, newChoices }: { index: number, newChoices: number[] }) {
    const theFilterOption = this.filterOptions[index]

    if (theFilterOption.type === FILTER_TYPES.CHECKBOX)
      theFilterOption.chosen = [...newChoices]

    else if (theFilterOption.type === FILTER_TYPES.MIN_MAX_RANGE)
      theFilterOption.range.chosen = [newChoices[0], newChoices[1]] // TODO: make this better

  }

  @Mutation removeFilter(subindex: number, index: number) {
    const theFilter = this.filterOptions[index]

    if (theFilter.type === FILTER_TYPES.CHECKBOX) {
      const choices = theFilter.chosen
      const idxInChoices = choices.findIndex(x => x === subindex)

      if (~idxInChoices) {
        choices.splice(idxInChoices, 1)
        this.updateFilter({
          index,
          newChoices: choices
        })
      }
    }
    else if (theFilter.type === FILTER_TYPES.MIN_MAX_RANGE) {
      theFilter.range.enabled = false
      // TODO: use vuex dispatch actions?
    }
  }

  @Mutation setGuideSchema(newGuideSchema: TableDefinition) {
    this.filterOptions = [...newGuideSchema.filterOptions]
    this.columnOptions = [...newGuideSchema.columnOptions]
    this.chosenColumns = [
      ...newGuideSchema.columnOptions
        .filter(col_obj => col_obj.default)
        .map(col_obj => col_obj.key)
    ]
  }

  @Mutation setGuideData(guideData: DataRow[]) {
    this.guideData = guideData
  }

  @Action async loadTableData() {

    this.context.commit('MainStore/set__loadingScreen', true, { root: true }) //TODO: can we do this in an OOP Typescript Intellisense way?

    this.clearFilters()

    const restructuredData = await fetchTableData()

    if (restructuredData !== false) {
      const newGuideDefiniton = PublicationsDefinition

      this.setGuideSchema(newGuideDefiniton)
      this.setGuideData(restructuredData)
    }
    else {
      console.error(`Could not fetch guide data from source.`)
    }

    this.context.commit('MainStore/set__loadingScreen', false, { root: true })
  }

  /**
   * URL LINK
   */

  get chosenFiltersForURLStruct(): FiltersChosenByKey {
    return this.filterOptions
      .map(filter => {
        if (isCheckboxFilterDefinition(filter) && filter.chosen.length) {
          const valueArr = filter.chosen
            .map(index => maybeCallFn(filter.items)[index])
            .map(normalizeCheckboxChoiceToValue)

          return { [filter.key]: valueArr }
        }
        else if (isMinMaxRangeFilterDefinition(filter) && filter.range.enabled) {
          return { [filter.key]: filter.range.chosen.map(String) }
        }
        else return {}
      })
      // We need to use deepmerge here, because some attribute keys are used in muliple checkbox group filters
      .reduce((prev, curr) => deepmerge(prev, curr), {})
  }

  get encodedColumnFilterQueryParam(): string {

    const struct: ColumnFilterQueryParam = {
      columns: this.chosenColumns
    }

    if (Object.keys(this.chosenFiltersForURLStruct).length)
      struct.filters = this.chosenFiltersForURLStruct

    try {
      const strung = JSON.stringify(struct)
      const encoded = Base64.encode(strung)
      return encoded
    }
    catch (e) {
      console.error(e)
      return ''
    }
  }

  @Action async handleInputQueryParam(encodedCFString: string) {
    try {
      const decoded = Base64.decode(encodedCFString)

      //  for now, assert parsed as ColumnFilterQueryParam
      const parsed = JSON.parse(decoded) as ColumnFilterQueryParam

      // TODO: check validity step where TS assertion is not necessary

      this.context.commit('set__chosenColumns', parsed.columns)

      if (parsed.filters)
        this.setChosenFilters(parsed.filters)
    }
    catch (e) {
      console.error(e)
    }
  }

  @Mutation setChosenFilters(input: FiltersChosenByKey) {

    this.filterOptions.forEach(filterDef => {

      const arrOfChoices = input[filterDef.key]

      if (!arrOfChoices) return

      if (isCheckboxFilterDefinition(filterDef)) {
        filterDef.chosen = maybeCallFn(filterDef.items)
          .map(normalizeCheckboxChoiceToValue)
          .map((item, index) => arrOfChoices.includes(item) ? index : null)
          .filter(isNotNull)
      }
      else if (isMinMaxRangeFilterDefinition(filterDef)) {

        filterDef.range.enabled = true;
        let [min, max] = arrOfChoices.slice(0, 2).map(Number)

        if (!Number.isNaN(min))
          min = Math.max(filterDef.range.min, min)
        else min = filterDef.range.min

        if (!Number.isNaN(max))
          max = Math.min(filterDef.range.max, max)
        else max = filterDef.range.max

        // Just in case, let's sort it
        const numberSorter = (a: number, b: number) => a > b ? 1 : a < b ? -1 : 0

        filterDef.range.chosen = [min, max].sort(numberSorter) as [number, number]
      }
      // else if (isRangeFilterDefinition(filterDef)) {
      // }
    })

  }

}
