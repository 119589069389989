



























import VueWithStore, {Component} from '@/mixins'
import BackToGelestButton from './minor/BackToGelestButton.vue';

@Component({
  components: {BackToGelestButton}
})
export default class TopHeader extends VueWithStore {}

