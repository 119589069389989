

























import VueWithStore, {Component, Prop} from '@/mixins'

import { isRangeFilterDefinition } from '@/schema'
import type { RangeFilterDefinition } from '@/types'

@Component
export default class RangeFilter extends VueWithStore {

  @Prop({required: true, validator: isRangeFilterDefinition}) source!: RangeFilterDefinition
  @Prop({required: true}) index!:  number

  processRangeFilterChange(index: number) {
    return (newValue: number) => {
      this.TableStore.updateFilter({
        index, newChoices: [newValue]
      })
    }
  }
}

