var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item-group',{staticClass:"item-group-grid",class:{'grid-is-collapsed': _vm.gridIsCollapsed},style:(("grid-template-rows: repeat(" + (_vm.gridIsCollapsed ? _vm.computedItemLimit + 2 : _vm.itemsWithIndices.length + 2) + ", auto);")),attrs:{"multiple":"","value":_vm.source.chosen},on:{"change":function (arg) { return _vm.processCheckboxesFilterChange(_vm.index)(arg); }}},[[_c('v-text-field',{staticClass:"drawer-search-input midgray--text ml-5 mb-4",style:({ borderRadius: '6px', width: '255px', gridArea: 'search' }),attrs:{"background-color":"white","outlined":"","dense":"","clearable":"","hide-details":"","single-line":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{class:{ marginTop: '14px' },attrs:{"color":"midgray"}},[_vm._v("$SearchMagnify")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"text-subtitle-2 midgray--text filter-placeholder "},[_vm._v("Find "+_vm._s(['a','e','i','o','u'].includes(_vm.source.name_single.toLocaleLowerCase().charAt(0)) ? 'an ' : 'a ')+_vm._s(_vm.source.name_single))])]},proxy:true}]),model:{value:(_vm.filterSearchInput),callback:function ($$v) {_vm.filterSearchInput=$$v},expression:"filterSearchInput"}})],_vm._l((_vm.filteredItems),function(itemObj,i){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(itemObj.visible),expression:"itemObj.visible"}],key:itemObj.value + i,staticClass:"mr-6 ml-2 checkbox-list-item",style:({order: i + 1}),attrs:{"ripple":false,"active-class":"force-to-top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{staticClass:" ml-n2"},[_c('v-checkbox',{staticClass:"TIF-checkbox mt-0",attrs:{"dense":"","on-icon":"$CheckboxChecked","off-icon":"$CheckboxUnchecked","hide-details":"","input-value":active},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-subtitle-2",class:active ? 'offblack-text' : 'midgray--text'},[_vm._v(_vm._s(itemObj.display_name || itemObj.value)+" "+_vm._s(_vm.parenthesesCount(itemObj)))])]},proxy:true},{key:"append",fn:function(){return [(itemObj.more_info)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"info-button",attrs:{"icon":"","x-small":"","ripple":false,"plain":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":hover ? '#E89F32' : 'gray'}},[_vm._v(" $InfoCircle ")])],1)]}}],null,true)})]}}],null,true)},[_c('v-card',{staticClass:"filter-popup"},[_c('v-card-title',{staticClass:"text-h2"},[_vm._v(" "+_vm._s(itemObj.display_name || itemObj.value)+" ")]),_c('v-divider'),_c('v-card-text',[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ")]),_c('v-divider',{staticClass:"my-5"}),_c('h3',{staticClass:"pb-3"},[_vm._v("More Information:")]),_c('p',[_c('a',{attrs:{"href":"#"}},[_c('b',[_vm._v(_vm._s(itemObj.display_name || itemObj.value)+" text link goes here")])])]),_c('p',[_c('a',{attrs:{"href":"#"}},[_c('b',[_vm._v(_vm._s(itemObj.display_name || itemObj.value)+" text link goes here")])])]),_c('p',[_c('a',{attrs:{"href":"#"}},[_c('b',[_vm._v(_vm._s(itemObj.display_name || itemObj.value)+" text link goes here")])])])],1)],1)],1):_vm._e()]},proxy:true}],null,true)})],1)]}}],null,true)})}),(_vm.showMoreLessButton)?_c('v-btn',{staticClass:"mr-6 pl-5 checkbox-list-item view-more-less-button",staticStyle:{"order":"9999","grid-area":"'viewmore'","grid-row-end":"-1","justify-content":"flex-start"},attrs:{"plain":"","ripple":false},on:{"click":function($event){_vm.truncationIsExpanded = !_vm.truncationIsExpanded}}},[_vm._v(" "+_vm._s(_vm.truncationIsExpanded ? "View less" : "View all")+"... ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }