var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
    handler: function () { return _vm.expandedDropdowns = []; },
    closeConditional: function () { return !!(_vm.expandedDropdowns.length); }
  }),expression:"{\n    handler: () => expandedDropdowns = [],\n    closeConditional: () => !!(expandedDropdowns.length)\n  }"}],staticClass:"customize-column-dropdown",class:{
    'mobile-positioning': _vm.mobile,
    'header-collapsed': _vm.MainStore.headerClosed
  },attrs:{"accordion":"","multiple":""},model:{value:(_vm.expandedDropdowns),callback:function ($$v) {_vm.expandedDropdowns=$$v},expression:"expandedDropdowns"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"primary--text",class:{'text-h5': !_vm.mobile},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v(" Customize Results ")]),_c('v-expansion-panel-content',{ref:"ccd-scrollable-content",class:{'scroll-stuck': _vm.customizeColumnDropdownIsOpen && _vm.mobile}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar@10","loading":_vm.loadingScreen}},[_c('v-list',_vm._l((_vm.columnOptions),function(item,index){return _c('v-list-item',{key:index,staticClass:"pt-2 pt-sm-1 pl-6 pl-sm-4",staticStyle:{"min-height":"unset"}},[_c('v-checkbox',{staticClass:"TIF-checkbox mt-0",attrs:{"value":item.key,"hide-details":"","dense":"","off-icon":_vm.mobile ? '$RadioButtonUncheckedLarge' : '$RadioButtonUnchecked',"on-icon":_vm.mobile ? '$RadioButtonCheckedLarge' : '$RadioButtonChecked'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:{
                      'offblack--text': _vm.chosenColumns.includes(item.key),
                      'midgray--text': !_vm.chosenColumns.includes(item.key),
                      'text-body-1': _vm.$vuetify.breakpoint.mobile,
                      'text-h6': !_vm.$vuetify.breakpoint.mobile
                    }},[_vm._v(" "+_vm._s(item.name)+" ")])]},proxy:true}],null,true),model:{value:(_vm.chosenColumns),callback:function ($$v) {_vm.chosenColumns=$$v},expression:"chosenColumns"}})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }