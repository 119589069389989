import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// INCLUDES
export * from './query'

import type { RouteConfig, RouterOptions } from 'vue-router'


// ROUTE SETUP

import Publications from '@/components/Publications.vue'

let routes: RouteConfig[] = [{
      path: '/',
      name: "publications",
      component: Publications
    }]

// DEV routes:
// These get tree-shaken away during prod compilation
if(process.env.NODE_ENV !== 'production') {
  routes.push({
    path: '/skeleton',
    // Use dynamic import for more tree shaking
    component: () => import('@/components/SkeletonOptions.vue')
  })
}

// All other routes:
routes = routes.concat([
  {
    path: '*', redirect: {name: "publications"}
  }
])

const options: RouterOptions = {
  mode: 'history',
  base: process.env.BASE_URL, // == vue.config.js "publicPath"
  routes
}

export default new VueRouter(options)
