
















import VueWithStore, {Component} from '@/mixins'

@Component
export default class BackToGelestButton extends VueWithStore {}

