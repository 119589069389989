import { FILTER_TYPES, TableDefinition } from '@/types'
import { caseInsensitiveExactStringMatchArrayIntersectFn, dateBetweenYearsMatchFn } from './matchers';

import { TableStore } from '@/store';

import dayjs from 'dayjs'

function dayjsDateSorter (a: dayjs.Dayjs, b: dayjs.Dayjs) {
  return a.isSame(b) ? 0 : a.isBefore(b) ? -1 : 1
}

export const PublicationsDefinition: TableDefinition = {
  filterOptions: [
    {
      name: "Authors",
      name_single: "Author",
      key: "author_names",
      type: FILTER_TYPES.CHECKBOX,
      chosen: [],
      // Anything in this file that reads from vuex needs to be a function
      // because the vuex store will not be instantiated at the time that this file is parsed:
      items: () => TableStore.listOfAuthors,
      item_count_map: () => TableStore.authorCounts,
      customMatchingFunction: caseInsensitiveExactStringMatchArrayIntersectFn
    },
    {
      name: "Journals",
      name_single: "Journal",
      key: "journal_name",
      type: FILTER_TYPES.CHECKBOX,
      chosen: [],
      items: () => TableStore.listOfJournals,
      item_count_map: () => TableStore.journalCounts,
    },
    {
      name: "Filter By Year",
      key: "date_published",
      type: FILTER_TYPES.MIN_MAX_RANGE,
      range: {
        min: 1999,
        max: 2022,
        enabled: false,
        chosen: [1970,2022],
        hint: 'to'
      },
      customMatchingFunction: dateBetweenYearsMatchFn
    },
    // {
    //   name: "Categories",
    //   name_single: "Category",
    //   key: "category_names",
    //   type: FILTER_TYPES.CHECKBOX,
    //   chosen: [],
    //   items: () => TableStore.listOfCategories,
    //   item_count_map: () => TableStore.categoryCounts,
    //   customMatchingFunction: caseInsensitiveExactStringMatchArrayIntersectFn
    // },
    // {
    //   name: "Application",
    //   key: MISSING_SLUG_PLACEHOLDER, //missing
    //   type: FILTER_TYPES.CHECKBOX,
    //   chosen: [],
    //   items: [
    //     "CVD",
    //     "Sol Gel",
    //     "Synthetic Intermediate",
    //     "Monomer",
    //     "Catalyst",
    //     "Inorganic",
    //   ],
    // },
    // {
    //   name: "Commercial Product",
    //   key: MISSING_SLUG_PLACEHOLDER, //missing
    //   type: FILTER_TYPES.CHECKBOX,
    //   chosen: [],
    //   items: [
    //     {value: "Yes", pill_name: "Commercial" },
    //     {value: "No" , pill_name: "Not Commercial" },
    //   ],
    //   customMatchingFunction: commercialMatcher
    // },
  ],
  columnOptions: [
    {
      name: "Year",
      key: 'date_published',
      default: true
    },
    {
      name: "Name",
      key: 'TIF___name',
      default: true
    },

    {
      name: "Volume",
      key: 'volume',
      default: false
    },
    {
      name: "Pages",
      key: 'pages',
      default: false
    },
    {
      name: "Journal",
      key: 'journal_name',
      default: true
    },
    {
      name: "Authors",
      key: 'author_names',
      default: true
    },
    // {
    //   name: "Categories",
    //   key: 'category_names',
    //   default: true
    // },
  ]
}
