import { TDomainMatcher, TMinMaxRangeMatcher } from '@/types'

import _intersectionBy from 'lodash/intersectionBy'
import _tolower        from 'lodash/toLower'

import dayjs from 'dayjs';

/*
  For TDomainMatcher
    `value` is the value coming in from each data row, to determine whether the data row matches or not
    `domain` is the list of ONLY the chosen filter values for the given filter, which is active.
      If there is more than one value in domain`, it is treated with an OR relationship.
      E.g. if the domain is [a,b], either value matches with "a" OR value matches wtih "b"
*/

export const caseInsensitiveExactStringMatchArrayIntersectFn: TDomainMatcher = (value: string[], domain: string[]) => {
  // For this case, `value` is an array of strings, and we want to see if there is at least one value
  //   in the intersection between each array.
  // E.g.     Data Row (`value`)          Chosen Filters (`domain`)     Return Value
  //          [apple, banana, cherry]     [plum, grape]                 false
  //          [apple, banana, cherry]     [plum, cherry]                true
  //          [apple, banana, cherry]     [banana, cherry]              true

  const intersect = _intersectionBy(value, domain, _tolower)

  return !!intersect.length
}

export const commercialMatcher: TDomainMatcher = (value: unknown, domain: string[]) => {

  let newValue = value;

  // Treat "commerical" in data as a "yes". Because only "yes" and "no" will be in the domain.
  if(value && String(value).toLocaleLowerCase() === 'commerical')
  {
    newValue = 'yes'
  }

  return caseInsensitiveExactStringMatchFn(newValue, domain)
}

export const refractiveIndexMatcher: TDomainMatcher = (value: unknown, domain: string[]) => {

  const numValue = Number(value)
  if(Number.isNaN(numValue)) return false

  if     (numValue < 1.4   &&                     domain.includes('low'))       return true
  else if(numValue >= 1.4  && numValue < 1.45 &&  domain.includes('standard'))  return true
  else if(numValue >= 1.45 && numValue < 1.5  &&  domain.includes('medium'))    return true
  else if(numValue >= 1.5  &&                     domain.includes('high'))      return true

  else return false
}

export const caseInsensitiveExactStringMatchFn: TDomainMatcher = (value: unknown, domain: string[]) => {
  // if we're doing any filtering at all on this domain, filter out empty values in the dataset
  if(!value) return false

  // this will check if the value is among the checked off possibilities for this filter item
  return [...domain].map(_tolower).includes(_tolower(String(value)))
}

export const numericMinMaxRangeMatchFn: TMinMaxRangeMatcher = (value: unknown, min: number, max: number) => {
  const valueAsNumber = Number(value)

  return min <= valueAsNumber && valueAsNumber <= max
}

export const dateBetweenYearsMatchFn: TMinMaxRangeMatcher = (date: dayjs.Dayjs | Date | string, min_year: number, max_year: number) => {

  const thedate = dayjs(date)

  if(!dayjs().isValid()) return false

  return thedate.isSameOrAfter(`${min_year}-01-01`, 'year') && thedate.isSameOrBefore(`${max_year}-01-01`, 'year')
}

export const defaultCheckboxMatchingFunction = caseInsensitiveExactStringMatchFn
export const defaultMinMaxrangeMatchingFunction = numericMinMaxRangeMatchFn
