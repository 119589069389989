

































































































































































































import VueWithStore, { Component, Watch } from '@/mixins'

import type { KeyValue } from '@/types'

import TIFClampedText   from '@/components/minor/TIFClampedText.vue'
import CopyUrlLink     from '@/components/minor/CopyUrlLink.vue'
import DownloadCsvLink from '@/components/minor/DownloadCsvLink.vue'

@Component({
  components: {TIFClampedText, CopyUrlLink, DownloadCsvLink}
})
export default class SearchMain extends VueWithStore {

    page = 1
    pageCount = 1
    numPerPage = 25

    sortBy: KeyValue | null = null
    sortDesc: boolean | null = true

    get loadingScreen()         { return this.MainStore.loadingScreen }
    get filteredGuideData()     { return this.TableStore.filteredGuideData }
    get listOfChosenColumns()   { return this.TableStore.listOfChosenColumns }

    get removeColumnByKey()     { return this.TableStore.removeColumnByKey }

    get tableHeaders() {
      return this.listOfChosenColumns.map(
        col_obj =>
        ({
            ...col_obj,
            text: col_obj.name,
            value: String(col_obj.key),
            sort: col_obj.customSortFunction || undefined
        })
      )

      /*
      {
        text: string,
        value: string,
        align?: 'start' | 'center' | 'end',
        sortable?: boolean,
        filterable?: boolean,
        groupable?: boolean,
        divider?: boolean,
        class?: string | string[],
        cellClass?: string | string[],
        width?: string | number,
        filter?: (value: any, search: string, item: any) => boolean,
        sort?: (a: any, b: any) => number
      }
      */
    }

    get numResultsText() {
      let x = `${this.filteredGuideData.length} Result`
      if (this.filteredGuideData.length !== 1) {
        x += 's'
      }
      return x
    }

    get currentPageRangeText() {
      const min = this.filteredGuideData.length ? (((this.page - 1) * this.numPerPage) + 1) : 0
      const max = Math.min((this.page * this.numPerPage), this.filteredGuideData.length)

      return `${min} - ${max}`
    }

    sortColumnByKey(key: KeyValue) {
      if(this.sortBy === key) {
        if(!this.sortDesc)
          this.sortDesc = true
        else
          this.sortBy = null
      }
      else {
        this.sortBy = key
        this.sortDesc = false
      }
    }

    @Watch('sortBy')
    @Watch('sortDesc')
    @Watch('filteredGuideData')
    resetToFirstPage() {
      this.page = 1
    }

    $refs!: {
      'gelest-results-table': Vue
    }

    scrollTableToTop() {
      this.$refs['gelest-results-table'].$el.querySelector('thead')?.scrollIntoView({behavior: 'smooth'});
    }

}
