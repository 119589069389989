import { VuexModule, Module } from 'vuex-module-decorators'
import { Mutable } from '../utils'

export const enum WIZARD_STEPS {
  STEP_1 = 1,
  STEP_2,
  STEP_3,
  SEARCH_SCREEN
}

@Module({name: 'MainStore', namespaced: true})
export default class MainStore extends VuexModule {

  @Mutable() wizard_step: WIZARD_STEPS = WIZARD_STEPS.SEARCH_SCREEN

  @Mutable() loadingScreen = false // TODO: fix error when @Mutable is not applied!


  // View States
  @Mutable() mobileModal = false
  @Mutable() drawerClosed = true
  @Mutable() headerClosed = false
}
