
























































import TIFMaybeLink from './TIFMaybeLink.vue'

import VClamp from 'vue-clamp'
import VueWithStore, { Component, Prop, Ref } from '@/mixins'

@Component({
  components: {VClamp, TIFMaybeLink}
})
export default class TIFClampedText extends VueWithStore {

  @Prop({required: true}) value!: string | undefined
  @Prop({required: false}) link!: string | undefined

  get trimmedValue() {
    return this.trimWhitespace(this.value);
  }

  containsHTMLTags(str: string | any) {
    const regex = new RegExp('<[^>]*>')
    return regex.test(str)
  }

  trimWhitespace(str: string | undefined) {
    return str?.replace(/^[\s]*(.+)[\s]*$/, '$1')
  }

  // Vue Clamp interactions:

  isClamped = false
  isExpanded = false

  @Ref() readonly the_clamper!: VClamp

  toggleClamper() {
    this.the_clamper.toggle()
  }
}

