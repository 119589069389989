///////////////////////
// UTILITY FUNCTIONS //
///////////////////////


//Promise-y setTimeout function
export const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));

/*
    Safe, recursive copying function for resetting Vuex states.
    Only copies items from src into dest if already defined in dest.
    Overwrite-copies arrays, but recursively copies props of nested objects.
    This is needed so Vuex does not lose its tracking references for state vars.
    Shallow copying a multi-depth object (a.k.a. overstamping it) could cause
      Vuex to lose track of nested state variables and mess up reactivity in app.

    Will not attempt to overwrite frozen dest objects, even shallowly frozen ones.

    By default, falsySrcValuesOverwrite == true. So values like null, 0, "" in src
      will overwrite their corresponding properties in dest, a.k.a. they are
      purposefully stamping out the dest data. If the flag is false, falsy values in
      src are treated as if the datum is not provided and will not overwrite dest props.
*/
export function copyIntoRecursive (dest={}, src={}, falsySrcValuesOverwrite = true) {
  if(Object.isFrozen(dest)) return dest;

  for(const key of Object.keys(dest))
  {
    if(!src.hasOwnProperty(key)) continue;

    if(
        dest[key] &&
        src[key] &&
        typeof dest[key] == "object" &&
        typeof src[key] == "object" &&
        !Array.isArray(dest[key]) &&
        !Array.isArray(src[key])
      )
    {
      if(!Object.isFrozen(dest[key])) {
        //recursively copy nested objects
        copyIntoRecursive (dest[key], src[key], falsySrcValuesOverwrite)
      }
    }
    else {
      if(falsySrcValuesOverwrite || src[key])
        dest[key] = src[key];
    }
  }
  return dest;
}

/*
  Generates a default setter of the form:
    
    variable(state, value) {
      state.variable = value;
    }

  Reduces boilerplate code!
*/
export function default_setter (key) { return (state, value) => state[key] = value; }

/*
  Generates a default getter of the form:
    
    variable(state) {
     return state.variable;
    }

  ** REALLY, you shouldn't need this. 
  Better to use mapState(['variable']) instead of mapGetters(['variable'])
  for trivial getters. mapGetters is only really useful for non-trivial computation
*/
export function default_getter (key) { return (state) => state[key] }


export function makeSetterMutations (array, prefix = "set__") {
  const obj = {}

  array.forEach(key => {
    obj[prefix + key] = default_setter(key)
  })

  return obj
}


import { mapState, mapMutations } from 'vuex'
export function mapSettableGetter (nameOfGetter, nameOfSetMutation = '', namespace = '') {
  
  if(!nameOfSetMutation)
    nameOfSetMutation = 'set__' + nameOfGetter;

  const getter_obj = namespace ? mapState(namespace, [nameOfGetter]) : mapState([nameOfGetter])
  const setter_obj = namespace ? mapMutations(namespace, [nameOfSetMutation]) : mapMutations([nameOfSetMutation])

  const get = Object.values(getter_obj)[0]
  const set = Object.values(setter_obj)[0]

  return { get, set }
}

export interface PendingPromise<T> extends Promise<T> {
  resolve: (value: unknown) => void
  reject: (reason?: any) => void
}

// https://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
export function makePendingPromise() {
  
  let res, rej;

	const promise = new Promise((resolve, reject) => {
		res = resolve;
		rej = reject;
	});
  
  const pendingPromise: PendingPromise<unknown> = {
    ...promise,
    resolve: res,
    reject: rej
  }

	return pendingPromise;
}


/*
// function Mutation(target, key, descriptor) {
//   var module = target.constructor;
//   if (!module.hasOwnProperty('mutations')) {
//       module.mutations = Object.assign({}, module.mutations);
//   }
//   var mutationFunction = descriptor.value;
//   var mutation = function (state, payload) {
//       mutationFunction.call(state, payload);
//   };
//   module.mutations[key] = mutation;
// }
//export declare function Mutation<T extends Object, R>(target: T, key: string | symbol, descriptor: TypedPropertyDescriptor<(...args: any[]) => R>): void;
*/ 

import { VuexModule, Mutation } from 'vuex-module-decorators'
/**
 * Vuex decorator to add a setter to a Vuex state property
 * 
 * Can be invoked as:
 * 
 * (a)Mutable() foo = 'bar';
 * // creates Mutation set__foo(newVal) { this.foo = newVal }
 * 
 * (a)Mutable('myFooSetter') foo = 'bar';
 * // creates Mutation myFooSetter(newVal) { this.foo = newVal }
 * 
 * @param setterName Optional, explicit name for setter
 * @author Rob Keleher (tryforceful)
 */
export function Mutable (setterName?: string) {
  return function (target: VuexModule, key: string | symbol) {

    if (setterName === void 0) { setterName = `set__${String(key)}`; }

    const setterFnObj = {
      value: function(payload) {
        this[key] = payload
      }
    }

    Mutation(target, setterName, setterFnObj)
  }
}


import { createDecorator } from 'vue-class-component';
/**
 * Decorator for a Vuex state property which was marked @Mutable in Vuex
 * 
 * @param module The Vuex module where the state property is defined
 * @param propName Supply this when the decorated property has a different name from the Vuex state property you're mapping to
 * @return PropertyDecorator | void
 * @author Rob Keleher
 */
export function MutableFrom<M extends VuexModule> ( module: M, propName?: string ) {
  return function (target: Vue, key: string) {
    if(propName === void 0) { propName = key }

    createDecorator(function (componentOptions, k) {
        ;
        (componentOptions.computed || (componentOptions.computed = {}))[k] = {
            get: function() {
                return module[propName!];
            },
            set: function (value) {
                module['set__' + propName](value)
            },
        };
    })(target, key);
  };
}

/**
 * Decorator for a Vuex state property or getter
 * 
 * @param module The Vuex module where the state property or getter is defined
 * @param propName Supply this when the decorated property has a different name from the Vuex state property or getter you're mapping to, in order to rename it
 * @return PropertyDecorator | void
 * @author Rob Keleher
 */
export function GetterFrom<M extends VuexModule> ( module: M, propName?: string ) {
  return function (target: Vue, key: string) {
    if(propName === void 0) { propName = key }

    createDecorator(function (componentOptions, k) {
        ;
        (componentOptions.computed || (componentOptions.computed = {}))[k] = {
            get: function () {
                return module[propName!];
            }
        };
    })(target, key);
  };
}

// declare type ConstructorOf<C> = {
//   new (...args: any[]): C;
// };

// import 'reflect-metadata'
// function logMetadata(
//   target: any,
//   propertyKey: string
// ) {
//   console.log(target)
//   console.log(propertyKey)
//   console.log(Reflect.getMetadata("design:type", target, propertyKey));
// }