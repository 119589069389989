






import VueWithStore, { Component } from '@/mixins'

import Publications from '@/components/Publications.vue'

@Component<GelestPublicationsApp>({
  components: {
    Publications
  },
  metaInfo() {
    return {
      title: "Gelest Publications Table"
    }
  }
})
export default class GelestPublicationsApp extends VueWithStore {}
