






























import VueWithStore, {Component, Prop} from '@/mixins'

import { ColumnDefinition, DataRow, KeyValue } from '@/types'

import Papa from 'papaparse'
import download from 'downloadjs'

@Component
export default class DownloadCsvLink extends VueWithStore {

  @Prop() downloadData!: DataRow[]

  get disabled() { return this.downloadData.length === 0 }


  get listOfChosenColumns() { return this.TableStore.listOfChosenColumns }


  get chosenColumnsForCSV() {
    return [
      ...this.listOfChosenColumns,
      // Make sure we explicitly include the permalink URL as a column
      {key: 'TIF___external_URL', name: 'Gelest_External_URL'} as ColumnDefinition
    ]
  }

  get csvColumnMap(): Record<KeyValue, string> {
    const mapArray = this.chosenColumnsForCSV.map( col_obj => {
      return ({[col_obj.key]: col_obj.name.replace(/\s/g, '_') })
    })

    return Object.assign( {}, ...mapArray )
  }

  // Not making this a computed getter so it's only calculated on demand
  filteredGuideDataForCSV(): Record<string, string>[] {
    return this.downloadData
      .map( row =>
        Object.fromEntries(
          Object.entries(row).map(
            ([fieldKey, fieldValue]) =>
            ([this.csvColumnMap[fieldKey] || fieldKey, fieldValue])
          )
        )
      )
  }

  downloadCSV() {
    function isNotNull<T> (arg: T): arg is Exclude<T, null> {
      return arg !== null
    }

    const columns = this.chosenColumnsForCSV
      .map( col_obj => this.csvColumnMap[col_obj.key] || col_obj.key )

    const csv = Papa.unparse(this.filteredGuideDataForCSV(), { columns, quotes: true })

    const csvAsBlob = new Blob([csv], {type: 'text/csv'})

    const filename = `GelestPublications-${Date.now()}`

    download(csvAsBlob, filename, 'text/csv')
  }
}

